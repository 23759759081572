<template>
    <div class="pa-3">
        <v-card flat :loading="loadingView">

            <v-card-text class="items">
                <v-row class="mb-2">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="2" class="pb-0">
                                <v-text-field :value="style" @input="setStyle" placeholder="Style" flat hide-details solo-inverted clearable></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pb-0">
                                <v-text-field :value="size" @input="setSize" placeholder="Size" flat hide-details solo-inverted clearable></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pb-0">
                                <v-select :value="type" @input="setType" :items="productTypes" placeholder="Item type" item-text="name" item-value="id" flat hide-details solo-inverted clearable></v-select>
                            </v-col>
                            <v-col cols="6" align="right">
                                <v-btn depressed dark @click="transferDialog = true">
                                    <v-icon left>mdi-swap-horizontal</v-icon> 
                                    Transfer out
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="3">
                                <v-select v-model="store" :items="[{ name: 'Warehouse', id: 99 }, ...stores]" item-text="name" item-value="id" 
                                    placeholder="Location the inventory belogs to" flat hide-details solo-inverted></v-select>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field v-model="barcode" placeholder="Barcode" flat hide-details solo-inverted clearable 
                                    @keydown.enter="setInventoriedItem"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-data-table :headers="itemHeaders" :items="items" :footer-props="paginationOptions" item-key="id" :options="pagination" :loading="loading">
                    <template v-slot:item.available="{ item }">
                        <div :class="'dot ' + (item.available ? 'success' : 'error')"></div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="transferDialog" width="500">
            <v-card>
                <v-card-title>Transfer</v-card-title>
                <v-card-text>
                    This will transfer all items that haven't been added to the inventory to the transfer code selected.

                    <div>
                        <br>
                        <strong>IMPORTANT:</strong> Make sure the paremeters are correctly. Once it's done this it cannot be reversed.
                        <br><br>
                    </div>

                    <v-form ref="transferForm">
                        <v-menu ref="transferDate" return-value.sync="transferDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="transferDate" placeholder="Last inventory date" append-icon="mdi-calendar" readonly 
                                    v-bind="attrs" v-on="on" dense flat solo-inverted class="my-2" hide-details :rules="[rules.required]"></v-text-field>
                            </template>
                            <v-date-picker v-model="transferDate" no-title scrollable></v-date-picker>
                        </v-menu>

                        <v-select v-model="transferCode" :items="transferCodes" item-value="id" item-text="code" dense 
                            placeholder="Transfer code" flat solo-inverted class="my-2" hide-details :rules="[rules.required]">
                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.code }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeTransferDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="transferItemsOut" :loading="transfering">Transfer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';

export default {
    data: () => ({
        itemHeaders                 : [
            { text: 'Barcode', value: 'barcode', sortable: true },
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Date inventoried', value: 'dateInventoried', sortable: true },
            { text: 'Available', value: 'available', sortable: true },
            { text: 'Transfer code', value: 'transferCode.code', sortable: true },
            { text: 'Order #', value: 'orderNum', sortable: true },
            { text: 'Turns', value: 'turns', sortable: true },
            { text: 'Cur. location', value: 'currentLocation', sortable: true },
            { text: 'Date out', value: 'dateOut', sortable: true },
            { text: 'Due back', value: 'dateDueBack', sortable: true },
            { text: 'Date added', value: 'dateCreated', sortable: true },
            { text: 'Serial #', value: 'id', sortable: true },
        ],
        pagination                  : {
            sortBy: ['Barcode']
        },
        paginationOptions           : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

        loading                     : false,
        loadingView                 : false,
        transfering                 : false,
        editDialog                  : false,
        deleteDialog                : false,
        transferDialog              : false,

        transferDate                : null,

        barcode                     : '',
        store                       : null,
        transferCode                : '',

        rules                       : {
            required                : v => !!v || 'required',
        },
    }),
    computed: {
        ...mapGetters({
            productTypes    : 'productType/items',
            transferCodes   : 'transferCode/items', 
            items           : 'inventory/verify/items',
            style           : 'inventory/verify/style',
            size            : 'inventory/verify/size',
            type            : 'inventory/verify/type',
            stores          : 'stores'
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadStores          : 'loadStores',
            loadProductTypes    : 'productType/load',
            loadTransferCodes   : 'transferCode/load',
            clearItems          : 'inventory/verify/clearItems',
            setStyle            : 'inventory/verify/setStyle',
            setSize             : 'inventory/verify/setSize',
            setType             : 'inventory/verify/setType',

            addToInventory      : 'inventory/verify/addToInventory',
            transferOut         : 'inventory/verify/transferOut',
        }),
        async init() {
            try {
                await this.loadStores();
                await this.loadProductTypes();
                await this.loadTransferCodes();
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        closeTransferDialog() {
            this.transferDialog = false;
            setTimeout(() => {
                this.$refs.transferForm.reset();
            }, 300);
        },
        setInventoriedItem() {
            try {
                if( _st.isNUE( this.barcode ) ) {
                    this.mxShowMessage( 'Barcode not set', 'warning' );
                    return;
                }
                if( _st.isNUE( this.store ) ) {
                    this.mxShowMessage( 'Location not set', 'warning' );
                    return;
                }
            
                this.loading = true;
                this.addToInventory({
                    barcode     : this.barcode,
                    style       : this.style,
                    size        : this.size,
                    categoryId  : this.type,
                    storeId     : this.store
                });
                this.loading = false;
                this.playBeep();
            }
            catch(error) {
                this.loading = false;
            }
            finally {
                this.barcode = '';
            }
        },
        async transferItemsOut() {
            if( !this.$refs.transferForm.validate() )
                return;

            try {
                this.transfering = true;
                await this.transferOut({
                    transferOutDate : this.transferDate,
                    transferCode    : this.transferCode
                });
                this.transfering = false;
                this.closeTransferDialog();
            }
            catch(error) {
                this.transfering = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.dot {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 0 auto;
}
</style>